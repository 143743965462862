@import "colours";

$font-family: 'Roboto', sans-serif;

$brand-primary-color: #2c0a3e;
$brand-secondary-color: #623e8c;
$brand-tertiary-color: #ad90cf;
$brand-danger-color: #dc3545;
$brand-success-color: #4CAF50;
$brand-warning-color: #ffc107;
$brand-info-color: #17a2b8;
$brand-light-color: #f8f9fa;
$brand-dark-color: #333333;

$color-white: #ffffff;
$color-black: #000000;
$color-dark-grey: #222222;
$color-dark-grey-alt: #444444;
$color-grey: #9e9e9e;

//FONT SIZES
$p-font-size: clamp(16px, 2.25vmin, 20px);
$h1-font-size: clamp(52px, 7vmin, 80px);
$h2-font-size: clamp(34px, 6vmin, 60px);
$h3-font-size: clamp(20px, 3.5vmin, 30px);
$h4-font-size: clamp(18px, 3vmin, 26px);
$h5-font-size: clamp(16px, 2.75vmin, 24px);

//BREAKPOINTS
$tablet: 768px;
$desktop: 998px;
$largeDesktop: 1366px;
