@import '../../../../../scss/global/common';
@import '../../../../../scss/global/variables';

.container {
  .charLimit {
    padding: 4px 12px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 0px 0px 2px 2px;
    border-right: 1px solid $brand-secondary-color;
    border-bottom: 1px solid $brand-secondary-color;
    border-left: 1px solid $brand-secondary-color;
  }

  .hasError {
    border-bottom: 2px solid #F44336;
  }

  .holder {
    position: relative;
  }

  .choices {
    position: absolute;
    top: 46px;
    left: 0;
    display: none;
    min-width: 100%;
    max-height: 280px;
    overflow-y: scroll;
    transition: all 500ms ease;
    background: #222;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $brand-primary-color;
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    &.open {
      display: block;
      z-index: 999;
      border: 1px solid $brand-tertiary-color;
      border-top: none;
    }

    .choice {
      display: flex;
      justify-content: start;
      align-items: flex-start;
      padding: 8px 12px;
      cursor: pointer;
      color: $color-white;
      background: #222;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }

      &:hover:not(.disabled) {
        color: $brand-tertiary-color;
        background-color: #111;
      }
    }
  }
}
