@import "../scss/global/variables";

.dialog {
  position: fixed;
  inset: 0 0 0 0;
  z-index: 10000;
  max-height: 100vh;
  animation: fadeIn 500ms;
  margin: 0;
  padding: 20px 20px 60px;
  height: 100vh;
  display: grid;
  overflow-y: auto;
  overflow-x: hidden;
  place-items: center;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.3);

  .container {
    color: $color-white;
    border-radius: 5px;
    max-width: 100%;
    box-shadow: 0 0 50px $color-dark-grey;
    border: 1px solid $brand-secondary-color;
    background: $color-dark-grey;

    .header {
      display: flex;
      padding: 15px;
      border-bottom: 1px solid $color-dark-grey-alt;

      h2 {
        margin: 0;
        font-size: 26px;
        width: 100%;
        align-self: center;
        padding-right: 20px;
        color: $brand-tertiary-color;
      }
    }

    .body {
      position: relative;
      padding: 20px;
      display: block;
    }
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
