@import '../../../../../scss/global/common';
@import '../../../../../scss/global/variables';

.container {
  input {
    outline: none;
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    resize: none;
    width: 100%;
    height: 46px;
    margin-bottom: 0;
    color: $color-white;
    letter-spacing: -0.28px;
    border-radius: 2px 2px 0px 0px;
    border-bottom: 2px solid $brand-tertiary-color;
    background-color: rgba(0, 0, 0, 0.1);

    &:read-only, &.disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .hasError {
    input {
      border-bottom: 2px solid #F44336 !important;
    }

    .charLimit {
      border-color: #F44336 !important;
    }
  }

  .charLimit {
    padding: 4px 12px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 0px 0px 2px 2px;
    border-right: 1px solid $brand-secondary-color;
    border-bottom: 1px solid $brand-secondary-color;
    border-left: 1px solid $brand-secondary-color;
  }
}
