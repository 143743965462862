@import '../../scss/global/variables';
@import '../../scss/global/animations';

.spinner {
  display: inline-block;
  position: relative;
  border: 8px solid white;
  border-top: 8px solid grey;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;

  &__small {
    width: 16px;
    height: 16px;
    border: 3px solid white;
    border-top: 3px solid grey;
  }

  img {
    display: inline-block;
    width: 25px;
    margin: 7px;
    animation: pulse 1.6s linear infinite;
    perspective: $desktop;
  }

  img:nth-child(1) {
    animation-delay: -0.5s;
  }

  img:nth-child(2) {
    animation-delay: -0.25s;
  }

  img:nth-child(3) {
    animation-delay: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: brightness(0.7);
  }
  50% {
    transform: scale(1.5);
    filter: brightness(1.4);
  }
  100% {
    transform: scale(1);
    filter: brightness(0.7);
  }
}
