@import '../../../../../scss/global/common';
@import '../../../../../scss/global/variables';

.container {
  .label {
    display: flex;
    gap: 8px;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.28px;
  }

  .dropdown {
    position: relative;
    display: flex;
    min-width: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    padding: 10px;
    width: 100%;
    height: 46px;
    cursor: pointer;
    align-self: stretch;
    color: #999;
    margin-bottom: 5px;
    border-bottom: 2px solid $brand-tertiary-color;
    background-color: rgba(0, 0, 0, 0.1);

    &__readOnly {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &__error {
      border-bottom: 2px solid #F44336;
    }

    .selected {
      width: 100%;
      height: 35px;
      display: inline-flex;
      gap: 12px;
      color: $brand-tertiary-color;
      align-items: center;
      justify-content: space-between;

      .text {
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        &.hasValue {
          color: $color-white;
        }
      }
    }

    .arrowUp {
      transition: all 500ms ease;
      filter: invert(100%);
      transform: rotate(270deg);
    }

    .arrowDown {
      transition: all 500ms ease;
      filter: invert(100%);
      transform: rotate(90deg);
    }

    .icons {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .clear {
      width: 22px;
      height: 22px;
      padding: 0;

      &:hover {
        background-color: green;
      }

      .icon {
        filter: contrast(100%) sepia(1) saturate(100%) brightness(0%) invert(1);
      }
    }

    .choices {
      position: absolute;
      top: 46px;
      left: 0;
      display: none;
      min-width: 100%;
      max-height: 280px;
      overflow-y: auto;
      transition: all 500ms ease;
      background: $color-white;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $brand-primary-color;
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }

      &.open {
        display: block;
        z-index: 999;
        border: 1px solid $brand-tertiary-color;
        border-top: none;
      }

      .choice {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 8px 12px;
        cursor: pointer;
        color: $color-white;
        background: #222;

        &.selected {
          color: $brand-primary-color;
          background-color: $brand-tertiary-color;
          cursor: default;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }

        &:hover:not(.disabled,.selected) {
          color: $brand-tertiary-color;
          background-color: #111;
        }
      }
    }
  }
}
