@import '../../scss/global/variables';

footer.footer {
  padding: 0 12px;
  box-sizing: border-box;
  background-color: #222;

  .listGroupItemTertiary {
    font-size: 14px;
    color: $color-white;
    background-color: transparent;

    &:hover {
      color: $brand-tertiary-color;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .bottom {
    background-color: #111;
  }

  .ageRestriction {
    padding: 3px;
    width: 28px;
    height: 28px;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    border-radius: 50%;
    border: 2px solid red;
    background-color: white;
    color: black;
    margin-right: 10px;
  }
}

.feedback {
  position: fixed;
  z-index: 1000;
  bottom: 100px;
  right: -48px;
  padding-bottom: 10px;
  transform: rotate(-90deg);
}
